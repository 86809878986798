import React from 'react'
import Feature from '../../../components/feature/Feature';
import './explore.css'

const Explore: React.FC = () => {
  return (
    <div className='kiria__explore section__margin' id='wkiria'>
      <div className='kiria__explore-feature'>
        <Feature text='El crecimiento de una empresa no se mide solo en cifras, sino en la capacidad de transformar ideas en soluciones innovadoras que mejoren la vida de las personas. Juntos, construimos el futuro del desarrollo de software'
        />
      </div>
      <div className='kiria__explore-heading'>
        <h1 className='gradient__text'>
        Explora nuevos caminos
        </h1>
        <p>Soluciones dinámicas e innovadoras</p>
      </div>
      <div className='kiria__explore-container'>
        <Feature title='Soluciones a Medida'
        text='Ofrecemos desarrollo de software personalizado que se adapta perfectamente a tus requisitos. Ya sea una aplicación móvil, una plataforma web o una solución de IA, tenemos la experiencia para hacer realidad tu visión.'
        />
        <Feature 
        title='Desarrollo Iterativo'
        text='Utilizamos un proceso de desarrollo iterativo, que nos permite ajustar y mejorar continuamente el producto según tus necesidades y feedback, asegurando así una solución final que realmente funcione para tu negocio.'
        />
        <Feature 
        title='Inteligencia Artificial y Big Data'
        text='Aprovechamos el poder de la inteligencia artificial y el análisis de datos para optimizar tus operaciones y tomar decisiones informadas. Creamos soluciones de IA y big data que te ayudan a entender y utilizar mejor tus datos.'
        />
      </div>
      </div>
  )
};

export default Explore;
