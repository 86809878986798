import { Box,  Typography } from '@mui/material';
import logoWhite from '../../../../../../assets/people/desing/logo_white_JOD.png';
import behance from '../../../../../../assets/people/desing/behance.png';
import '../../jahilimOsorio.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer: React.FC = () => {

  return (
        <Box className="footer_jod" >
          <Box display="flex"  justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="column" justifyContent="flex-start" m={3}>
              <img src={logoWhite} alt="Logo" className='logo_footer'/>
              <Typography fontSize={7} color="white">
                By Kiria Group
                {'Copyright © '}  
                {new Date().getFullYear()}
                {'.'}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" p={1} mr={5}>
            <a href="https://www.linkedin.com/in/jahilim-osorio-delgado-08180a1a2/"  target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}} >
              <LinkedInIcon  fontSize="large" />
            </a>
            <a href="https://www.instagram.com/lim_006/"  target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}} >
              <InstagramIcon  style={{marginLeft:'3px'}} fontSize="large" />
            </a>
            <a href="https://www.behance.net/jahilimosorio"   target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}} >
              <img src={behance} alt="Behance"  style={{marginLeft:'6px', height: '39px' , paddingBottom:'4px' }} />
            </a>
          </Box>
          </Box>
        </Box>
  );
}

export default Footer;
