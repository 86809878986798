import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../assets/people/desing/logo_JOD.png';
import BasicNavbar from './components/Navbar/BasicNavbar';
import Footer from './components/Footer/Footer';
import './jahilimOsorio.css';

const JahilimOsorio: React.FC = () => {
  const links = [
    { name: 'About me', path: '/people/jahilim-osorio/about-JOD' },
    { name: 'Projects', path: '/people/jahilim-osorio/projects-JOD'  },
    { name: 'Contact', path: 'mailto:jahilim29@gmail.com '  },
  ];
  const aboutJOD = useRef<HTMLDivElement>(null);
  const projectsJOD = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollAndResetUrl = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        navigate('/people/jahilim-osorio/', { replace: true });
      }
    };

    if (id === 'about-JOD') {
      scrollAndResetUrl(aboutJOD);
    } else if (id === 'projects-JOD') {
      scrollAndResetUrl(projectsJOD);
    }
  }, [id, navigate]);

  return (
      <div>    
       <Helmet>
        <title> Jahilim Osorio - Portfolio</title>
        <meta name="title" content="Servicios de diseño Colombia Jahilim Osorio Diseñadora Portfolio" />
        <meta name="description" content='Graphic designer Colombia Jahilim Osorio Portfolio' />
        <meta name="description" content='Graphic designer Jahilim Osorio Colombia Portfolio' />
        <meta name="keywords" content="Jahilim Osorio, Jahilim, Diseñador Grafico, Jahilim Osorio Diseñadora Grafica, Portfolio Diseñador Grafico, Diseñadora Grafica Colombia" />
      </Helmet>
      <div className="background_jod">
        <BasicNavbar logoSrc={logo} links={links} />

        <Grid  spacing={4} style={{ marginTop: '20px' }} className='banner_background_section'>
          <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Banner-home-eyes.png" alt="eyes_1" className='banner_eyes' />
          <Box className='name_jod'  > 
          <Typography variant="h1"  className='title_name' fontSize={285} fontFamily={'LeMurmure'} >
          Jahilim
          <br/>
          </Typography>
          <Typography variant="h1" className='title_lastname' fontSize={285} fontFamily={'LeMurmure'} >
          Osorio
          </Typography>
          <Typography className='title_lastname_2' fontSize={62} fontFamily={'Raleway'} >
          Delgado
          </Typography>
          </Box>
       </Grid>
       <div ref={projectsJOD}></div>
        <Grid container justifyContent="center" style={{ marginTop: '40px' }}>
    {/* Primera fila */}
    <Grid container item xs={12} sm={12} md={12} justifyContent="center">
      <Grid item xs={12} sm={4} md={4} display="flex" justifyContent="center">
        <a href="/people/jahilim-osorio/editorial" style={{ textDecoration: 'none' }}>
          <Box textAlign="center" style={{ width: '348px', position: 'relative' }}>
            <img
              src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Editorial.png"
              alt="Editorial"
              style={{ width: '348px', height: '348px' }}
            />
            <Typography
              className="category_titles"
            >
              Editorial
            </Typography>
          </Box>
        </a>
      </Grid>
      <Grid item xs={12} sm={4} md={4} display="flex" justifyContent="center">
        <a href="/people/jahilim-osorio/motion-graphics" style={{ textDecoration: 'none' }}>
          <Box textAlign="center" style={{ width: '348px', position: 'relative' }}>
            <img
              src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Motion.png"
              alt="Motion_grapics"
              style={{ width: '348px', height: '348px' }}
            />
            <Typography
              className="category_titles"
            >
              MoGraph
            </Typography>
          </Box>
        </a>
      </Grid>
      <Grid item xs={12} sm={4} md={4} display="flex" justifyContent="center">
        <a href="/people/jahilim-osorio/branding-2" style={{ textDecoration: 'none' }}>
          <Box textAlign="center" style={{ width: '348px', position: 'relative' }}>
            <img
              src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Branding.png"
              alt="Branding"
              style={{ width: '348px', height: '348px' }}
            />
            <Typography
              className="category_titles"
            >
              Rebranding
            </Typography>
          </Box>
        </a>
      </Grid>
    </Grid>
    {/* Segunda fila */}
    <Grid container item xs={12} sm={12} md={12} justifyContent="center">
      <Grid item xs={12} sm={4} md={4} display="flex" justifyContent="center">
        <a href="/people/jahilim-osorio/branding" style={{ textDecoration: 'none' }}>
          <Box textAlign="center" style={{ width: '348px', position: 'relative' }}>
            <img
              src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/burger.png"
              alt="Photography"
              style={{ width: '348px', height: '348px' }}
            />
            <Typography
              className="category_titles"
            >
              Branding
            </Typography>
          </Box>
        </a>
      </Grid>
      <Grid item xs={12} sm={4} md={4} display="flex" justifyContent="center">
        <a href="/people/jahilim-osorio/kevin" style={{ textDecoration: 'none' }}>
          <Box textAlign="center" style={{ width: '348px', position: 'relative' }}>
            <img
              src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/lps/kevin.png"
              alt="Web Design"
              style={{ width: '348px', height: '348px' }}
            />
            <Typography
              className="category_titles"
            >
              Conceptual
            </Typography>
          </Box>
        </a>
      </Grid>
    </Grid>
  </Grid>
        <div ref={aboutJOD}></div>
        {/* About Section */}
        <Grid  container spacing={4} style={{ marginTop: '20px' }} className='banner_background_section_2' >
            <Grid container item xs={12} sm={6} md={6} display="flex" justifyContent="center" >
              <Box textAlign='center'  >
                <Typography  variant="h1"  className='who' fontSize={280} fontFamily={'LeMurmure'}>¿WHO </Typography>
                <Typography  fontFamily={"Raleway"} className='passionate_text'>
                I am a passionate graphic designer seeking opportunities to apply my creativity and technical skills in challenging projects. Recently graduated in Graphic Design from Jorge Tadeo Lozano University, I possess a solid theoretical and practical foundation in the latest design tools and techniques. My approach is based on the combination of innovative concepts, visual aesthetics, and effective solutions to meet client expectations. I am a passionate graphic designer seeking opportunities to apply.
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} sm={6} md={6} display="flex" justifyContent="center" >
              <Box textAlign='center'  >
                <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Jahilim_Retrato.png" alt="JOD" className='JOD_photo' />
                <Typography variant="h1"  className='iam' fontSize={280} fontFamily={'LeMurmure'}>I AM?</Typography>
              </Box>
          </Grid>
        </Grid>
        <Paper >
            <Grid container item xs={12} sm={12} md={12} display="flex" justifyContent="center" className='gallery_grid' >
              <Box textAlign='center' className='banner_background_section_3'>
                <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Gallery.png" alt="JOD" className='JOD_photo_gallery' />
                <Typography variant="h1"  className='gallery' fontSize={250} fontFamily={'LeMurmure'}>Gallery</Typography>
              </Box>
          </Grid>
        </Paper>

      <Footer/>
      </div>
    </div>
  );
}

export default JahilimOsorio;
