import React from 'react';
import './feature.css';  // Importamos los estilos de la navbar

interface FeatureProps {
    title?: string;
    text:  string;
  }

const Feature: React.FC<FeatureProps> = ({title, text}) => {
    return (
        <div className='kiria__features-container__feature'>
          <div className='kiria__features-container__feature-title'>
           {title && <div />}
           <h1>{title}</h1> 
          </div>
          <div className='kiria__features-container_feature-text'>
            <p>{text}</p>
          </div>
        </div>
      )
};

export default Feature;
