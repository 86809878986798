import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../../assets/people/desing/logo_JOD.png';
import BasicNavbar from '../components/Navbar/BasicNavbar';
import Footer from '../components/Footer/Footer';
import '../jahilimOsorio.css';
import Description  from '../components/Description/Description';

const JahilimOsorio_Burger: React.FC = () => {
  const links = [
    { name: 'About me', path: '/people/jahilim-osorio/about-JOD' },
    { name: 'Projects', path: '/people/jahilim-osorio/projects-JOD'  },
    { name: 'Contact', path: 'mailto:jahilim29@gmail.com '  },
  ];
  const aboutJOD = useRef<HTMLDivElement>(null);
  const projectsJOD = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollAndResetUrl = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        navigate('/people/jahilim-osorio/', { replace: true });
      }
    };

    if (id === 'about-JOD') {
      scrollAndResetUrl(aboutJOD);
    } else if (id === 'projects-JOD') {
      scrollAndResetUrl(projectsJOD);
    }
  }, [id, navigate]);

  return (
      <div>    
       <Helmet>
        <title> Jahilim Osorio - Portfolio</title>
        <meta name="title" content="Servicios de diseño Colombia Jahilim Osorio Diseñadora Portfolio" />
        <meta name="description" content='Graphic designer Colombia Jahilim Osorio Portfolio' />
        <meta name="description" content='Graphic designer Jahilim Osorio Colombia Portfolio' />
        <meta name="keywords" content="Jahilim Osorio, Jahilim, Diseñador Grafico, Jahilim Osorio Diseñadora Grafica, Portfolio Diseñador Grafico, Diseñadora Grafica Colombia" />
      </Helmet>
      <div className="background_jod">
        <BasicNavbar logoSrc={logo} links={links} />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/Banner.jpg" alt="Banner-burger"  className='fullSize' />
          <Grid item xs={12} display="flex" justifyContent="end" mr={2}>
            <Typography fontFamily="raleway" fontSize={27} style={{fontStyle:"italic"}}>Project developed at Vasto Estudio</Typography>
          </Grid>
          <Description   
            description="Just-a Burger is an artisanal burger restaurant"
            category="branding  ilustration"
            color='#FF5A00'
            paragraph="that competes in a highly demanding niche. In pursuit of a re-evaluation and brand enhancement, a rebranding was undertaken to revitalize the original brand, strengthening its elements and anchoring conceptual and distinctive graphic features rich in differentiation."/>     
             
        <Grid container style={{marginTop: '20px' , marginBottom:'20px' }}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box display="flex" justifyContent="center" alignItems="center" style={{ background: "#fcf7ef", width: '100%' }}>
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/1.png" alt="1-burger" className="two_images" />
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/2.png" alt="2-burger" className="two_images" />
            </Box>
          </Grid>
        </Grid>

        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/3 gif slogan.gif" alt="Banner-burger"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/4.png" alt="Banner-burger"  className='fullSize' />
        
        <Grid container style={{marginTop: '20px' , marginBottom:'20px' }}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box display="flex" justifyContent="center" alignItems="center" style={{ background: "#fcf7ef", width: '100%' }}>
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/5.gif" alt="Banner-burger" className="two_images" />
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/6.gif" alt="Banner-burger" className="two_images" />
            </Box>
          </Grid>
        </Grid>

        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/7.gif" alt="Banner-burger"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/8.png" alt="Banner-burger"  className='fullSize' />
        
        <Grid container style={{marginTop: '20px' , marginBottom:'20px' }}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box display="flex" justifyContent="center" alignItems="center" style={{ background: "#fcf7ef", width: '100%' }}>
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/9.png" alt="Banner-burger" className="two_images" />
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/10.gif" alt="Banner-burger" className="two_images" />
            </Box>
          </Grid>
        </Grid>

        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/11.gif" alt="Banner-burger" className="two_images" />
        
        <Grid container style={{marginTop: '20px' , marginBottom:'20px' }}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box display="flex" justifyContent="center" alignItems="center" style={{ background: "#fcf7ef", width: '100%' }}>
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/12.png" alt="Banner-burger" className="two_images" />
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/13.png" alt="Banner-burger" className="two_images" />
            </Box>
          </Grid>
        </Grid>

        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/14.png" alt="Banner-burger" className="two_images" />
        
        <Grid container style={{marginTop: '20px' , marginBottom:'20px' }}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box display="flex" justifyContent="center" alignItems="center" style={{ background: "#fcf7ef", width: '100%' }}>
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/15.png" alt="Banner-burger" className="two_images" />
              <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Just+a+burger+/16.png" alt="Banner-burger" className="two_images" />
            </Box>
          </Grid>
        </Grid>
       <Footer/>
      </div>
    </div>
  );
}

export default JahilimOsorio_Burger;
