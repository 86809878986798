import React from 'react';
import './navbarKiria.css';  // Importamos los estilos de la navbar

const Menu = () => (
    <>
            <p><a href="#home">Home</a></p>
            <p><a href="#wkiria">¿Como trabajamos?</a></p>
           
    </>
  )

const BasicNavbar: React.FC = () => {
    
  return (
    <div className='kiria__navbar'>
    <div className='kiria__navbar-links'>
      <div className='kiria__navbar-links_logo'>
        <h1>KIRIA GROUP</h1>
      </div>
      <div className='kiria__navbar-links_container'>
        <Menu />
      </div>
    </div>

  <div className='kiria__navbar-menu'>

  </div>
  </div>
  );
};

export default BasicNavbar;
