import React from 'react'
import Feature from '../../../components/feature/Feature';
import './how.css'

const featuresData = [
    {
      title: '¿Qué tecnologías utilizan? ',
      text: 'Utilizamos una amplia gama de tecnologías, incluyendo pero no limitadas a, inteligencia artificial, big data, desarrollo web y móvil, asegurando que podamos proporcionar la mejor solución para tus necesidades específicas.'
    },
    {
      title: '¿Qué es el desarrollo iterativo?',
      text: 'El desarrollo iterativo es un enfoque en el cual el proyecto se desarrolla en pequeñas etapas o iteraciones. Cada iteración incluye planificación, diseño, desarrollo y revisión, permitiendo ajustes y mejoras continuas basadas en el feedback del cliente.'
    },
    {
      title: '¿Cuánto cuesta un proyecto de desarrollo de software?',
      text: 'El costo de un proyecto varía según su complejidad y alcance. Trabajamos contigo para entender tus necesidades y ofrecer una solución que se ajuste a tu presupuesto, sin comprometer la calidad.'
    },
  ]

const How: React.FC = () => {
  return (
    <div className='kiria__features section__padding' id='features'>
      <div className='kiria__features-heading'>
        <h1 className='gradient__text'>
        Nuestro crecimiento se basa en escuchar a nuestros usuarios. Transformamos sus necesidades en historias claras, definimos metas ambiciosas y creamos entregables que superan expectativas. Así, cada proyecto es un nuevo éxito compartido.
        </h1>
     
      </div>
      <div className='kiria__features-container'>

        {featuresData.map(
          (item, index) => (
            <Feature
              title={item.title}
              text={item.text}
              key={item.title + '_' + index}
            />
          )
        )}

      </div>
      </div>
  )
};

export default How;
