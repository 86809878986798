import React from 'react';
import possibility from '../../../assets/home/possibility.png'
import './header.css'

const Header: React.FC = () => {
    return (
        <div className='kiria__header section__padding' id='home'>
           <div className='kiria__header-image'>
              <img src={possibility} alt="possibility" />
            </div>
          <div className='kiria__header-content'>
            <h1 className='gradient__text'>
              Creemos soluciones juntos
            </h1>
            <p>En Kiria, transformamos tus ideas en soluciones tecnológicas efectivas y a medida. Nuestra misión es ofrecer servicios de desarrollo de software que se ajusten a tus necesidades y presupuesto, garantizando calidad, escalabilidad y satisfacción en cada proyecto. </p>
            <div className='kiria__header-content__input'>
              <input type="email" placeholder='Tu correo electrónico' />
              <button type='button'>Escribeme!</button>
            </div>
         
          </div>
          </div>
      )
};

export default Header;
