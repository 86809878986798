import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../../assets/people/desing/logo_JOD.png';
import BasicNavbar from '../components/Navbar/BasicNavbar';
import Footer from '../components/Footer/Footer';
import '../jahilimOsorio.css';
import { Grid, Typography} from '@mui/material';
import Description from '../components/Description/Description';

const JahilimOsorio_Motion: React.FC = () => {
  const links = [
    { name: 'About me', path: '/people/jahilim-osorio/about-JOD' },
    { name: 'Projects', path: '/people/jahilim-osorio/projects-JOD'  },
    { name: 'Contact', path: 'mailto:jahilim29@gmail.com '  },
  ];
  const aboutJOD = useRef<HTMLDivElement>(null);
  const projectsJOD = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollAndResetUrl = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        navigate('/people/jahilim-osorio/', { replace: true });
      }
    };

    if (id === 'about-JOD') {
      scrollAndResetUrl(aboutJOD);
    } else if (id === 'projects-JOD') {
      scrollAndResetUrl(projectsJOD);
    }
  }, [id, navigate]);

  return (
      <div>    
       <Helmet>
        <title> Jahilim Osorio - Portfolio</title>
        <meta name="title" content="Servicios de diseño Colombia Jahilim Osorio Diseñadora Portfolio" />
        <meta name="description" content='Graphic designer Colombia Jahilim Osorio Portfolio' />
        <meta name="description" content='Graphic designer Jahilim Osorio Colombia Portfolio' />
        <meta name="keywords" content="Jahilim Osorio, Jahilim, Diseñador Grafico, Jahilim Osorio Diseñadora Grafica, Portfolio Diseñador Grafico, Diseñadora Grafica Colombia" />
      </Helmet>
      <div className="background_jod">
        <BasicNavbar logoSrc={logo} links={links} />

        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/motion/motion-banner.png" alt="Banner-motion" className="fullSize" />
        <Description   
            description="universe of introspection and expression"
            category="motion graphics"
            color='#FFC900'
            paragraph="through a fusion of typography and illustration, delving into the fragmented parts of the human mind while exploring the complexities of dissociative identity disorder."
        /> 
      
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/motion/motion-1.png" alt="Motion-1" className="fullSize" />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/motion/motion-2.png" alt="Motion-2" className="fullSize" />


       <Footer/>
      </div>
    </div>
  );
}

export default JahilimOsorio_Motion;
