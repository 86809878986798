import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RouteTracker from './analytics/RouteTracker';
import JahilimOsorio from './pages/people/desing/jahilimOsorio/JahilimOsorio';
import JahilimOsorio_Editorial from './pages/people/desing/jahilimOsorio/pages/JahilimOsorio_Editorial';
import JahilimOsorio_Motion from './pages/people/desing/jahilimOsorio/pages/JahilimOsorio_Motion';
import JahilimOsorio_Burger from './pages/people/desing/jahilimOsorio/pages/JahilimOsorio_Branding_Burger';
import JahilimOsorio_Kuty from './pages/people/desing/jahilimOsorio/pages/JahilimOsorio_Branding_Kuty';
import JahilimOsorio_Kevin from './pages/people/desing/jahilimOsorio/pages/JahilimOsorio_Kevin';

import Home from './pages/home/Home';
import { Helmet } from 'react-helmet-async';



const App: React.FC = () => {
  return (
    <BrowserRouter >
      <Helmet>
      <title>Kiria Group - Colombia</title>
      <meta name="title" content="Servicios de Software y Diseño para PYMEs en Pereira | Kiria Group"/>
      <meta name="description" content="Ofrecemos soluciones de software personalizadas y servicios de diseño creativo para pequeñas y medianas empresas en Pereira. Optimiza tu negocio con nuestras aplicaciones, desarrollo web y diseño gráfico. Contacta a Kiria Group para más información."/>
      <meta name="description" content="Develop and design software in Pereira"/>
      <meta name="description" content="We offer customized software solutions and creative design services for small and medium-sized businesses in Pereira. Optimize your business with our applications, web development, and graphic design. Contact Kiria Group for more information."/>
      <meta name="keywords" content="Servicios de software Pereira, Diseño gráfico Pereira, Desarrollo de software Pereira, Soluciones TI PYMEs Pereira, Desarrollo web Pereira, Diseño web Pereira, Software para pequeñas y medianas empresas Pereira, Servicios de TI Pereira, Kiria Group"/>
      <meta name="keywords" content="Software services Pereira, Graphic design Pereira, Software development Pereira, IT solutions SMEs Pereira, Web development Pereira, Web design Pereira, Software for small and medium businesses Pereira, IT services Pereira, Kiria Group"/>
      <meta property="og:title" content="Servicios de Software y Diseño para PYMEs en Pereira | Kiria Group"/>
      <meta property="og:description" content="Ofrecemos soluciones de software personalizadas y servicios de diseño creativo para pequeñas y medianas empresas en Pereira. Optimiza tu negocio con nuestras aplicaciones, desarrollo web y diseño gráfico. Contacta a Kiria Group para más información."/>
      <meta property="og:image" content="https://kiriaweb.s3.amazonaws.com/Logo.png"/>
      <meta property="og:url" content="www.kiriagroup.com"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="Software and Design Services for SMEs in Pereira | Kiria Group"/>
      <meta property="og:description" content="We offer customized software solutions and creative design services for small and medium-sized businesses in Pereira. Optimize your business with our applications, web development, and graphic design. Contact Kiria Group for more information."/>
      <meta property="og:image" content="[URL de la imagen de tu empresa]"/>
      <meta property="og:url" content="www.kiriagroup.com"/>
      <meta property="og:type" content="website"/>
      </Helmet>
      <RouteTracker />
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/people/jahilim-osorio" element={<JahilimOsorio />} />
        <Route path="/people/jahilim-osorio/:id" element={<JahilimOsorio />} />
        <Route path="/people/jahilim-osorio/editorial" element={<JahilimOsorio_Editorial />} />
        <Route path="/people/jahilim-osorio/motion-graphics" element={<JahilimOsorio_Motion />} />
        <Route path="/people/jahilim-osorio/branding" element={<JahilimOsorio_Burger/>} />
        <Route path="/people/jahilim-osorio/branding-2" element={<JahilimOsorio_Kuty/>} />
        <Route path="/people/jahilim-osorio/kevin" element={<JahilimOsorio_Kevin/>} />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
