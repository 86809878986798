import React from 'react';
import NavbarKiria from '../../components/navbars/NavbarKiria';
import Header from '../../pages/home/header/Header';
import Explore from './explore/Explore';
import Footer from './footer/Footer';
import './home.css';
import How from './how/How';

const Home: React.FC = () => {
  return (
      <div className = "gradient__bg">
        <NavbarKiria  />
        <Header />
        <Explore />
        <How />
        <Footer />
      </div>
  );
};

export default Home;
