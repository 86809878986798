import { Grid, Typography } from "@mui/material";

interface DescriptionProps {
    description: string;
    category: string;
    color: string;
    paragraph: string;
  }
const Description: React.FC<DescriptionProps> = ({ description,category,color, paragraph })  => {

  return (
    <Grid container style={{marginTop: '30px' , marginBottom:'30px' }}   sx={{ margin: { xs: '15px', sm: '0px' } }}>
    <Grid item xs={12} sm={6} pr={7} >
      <Typography fontFamily="raleway" display="flex" justifyContent="end"  fontSize={27} >{description}</Typography>
      <Typography display="flex" justifyContent="end" fontFamily="LeMurmure" fontSize={44} style={{fontStyle:"", color:color}}>{category}</Typography>
    </Grid>
    <Grid item xs={12} sm={6} display="flex" justifyContent="end" pr={15}>
      <Typography fontFamily="raleway" fontSize={27} style={{}}>{paragraph}</Typography>
    </Grid>
    </Grid>
  );
}

export default Description;
