import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './basicNavbar.css'; // Importamos los estilos de la navbar

interface NavbarProps {
  logoSrc: string;
  links: { name: string, path: string }[];
}

const BasicNavbar: React.FC<NavbarProps> = ({ logoSrc, links }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {links.map((link, index) => (
          <ListItem button key={index} component={Link} to={link.path}>
            <ListItemText primary={link.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="sticky" className="navbar">
        <Toolbar className='navbar_content'>
          <a href="/people/jahilim-osorio/" style={{ textDecoration: 'none'}} >
            <div className="navbar-logo">
              <img src={logoSrc} alt="Logo" />
            </div>
          </a>
          <div className="navbar-links">
            {links.map((link, index) => (
              <Typography key={index} variant="h6" component={Link} to={link.path}>
                {link.name}
              </Typography>
            ))}
          </div>
          <IconButton
            edge="end"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            className="menu-icon"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

export default BasicNavbar;
