import ReactGA from 'react-ga';

export const initGA = () => {
  const trackingId = 'G-RX03G5L0PG';
  if (trackingId) {
    ReactGA.initialize(trackingId);
  } else {
    console.error('Google Analytics tracking ID is missing');
  }
};

export const logPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const logEvent = (category: string, action: string, label?: string) => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};
