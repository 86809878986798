import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../../assets/people/desing/logo_JOD.png';
import BasicNavbar from '../components/Navbar/BasicNavbar';
import Footer from '../components/Footer/Footer';
import '../jahilimOsorio.css';
import Description  from '../components/Description/Description';

const JahilimOsorio_Kevin: React.FC = () => {
  const links = [
    { name: 'About me', path: '/people/jahilim-osorio/about-JOD' },
    { name: 'Projects', path: '/people/jahilim-osorio/projects-JOD'  },
    { name: 'Contact', path: 'mailto:jahilim29@gmail.com '  },
  ];
  const aboutJOD = useRef<HTMLDivElement>(null);
  const projectsJOD = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollAndResetUrl = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        navigate('/people/jahilim-osorio/', { replace: true });
      }
    };

    if (id === 'about-JOD') {
      scrollAndResetUrl(aboutJOD);
    } else if (id === 'projects-JOD') {
      scrollAndResetUrl(projectsJOD);
    }
  }, [id, navigate]);

  return (
      <div>    
       <Helmet>
        <title> Jahilim Osorio - Portfolio</title>
        <meta name="title" content="Servicios de diseño Colombia Jahilim Osorio Diseñadora Portfolio" />
        <meta name="description" content='Graphic designer Colombia Jahilim Osorio Portfolio' />
        <meta name="description" content='Graphic designer Jahilim Osorio Colombia Portfolio' />
        <meta name="keywords" content="Jahilim Osorio, Jahilim, Diseñador Grafico, Jahilim Osorio Diseñadora Grafica, Portfolio Diseñador Grafico, Diseñadora Grafica Colombia" />
      </Helmet>
      <div className="background_jod">
        <BasicNavbar logoSrc={logo} links={links} />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/lps/Banner.png" alt="Banner-burger"  className='fullSize' />
          <Description   
            description="A collection of vinyl records by the artist Kevin Johansen"
            category="tipography  conceptual   fotography"
            color='#9B4D2B'
            paragraph="conceptually exploring some of his compositions such as the albums “The Nada” and “Algoritmos.” This includes vinyl record covers and collectible posters featuring his lyrics and graphics, complementing the vinyl record system."/>     
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/lps/7.gif" alt="kevingif"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/lps/6.png" alt="kevin3"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/lps/2.jpg " alt="kevin2"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/lps/8.jpg" alt="kevin9"  className='fullSize' />

       <Footer/>
      </div>
    </div>
  );
}

export default JahilimOsorio_Kevin;
