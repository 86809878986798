import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../../assets/people/desing/logo_JOD.png';
import BasicNavbar from '../components/Navbar/BasicNavbar';
import Footer from '../components/Footer/Footer';
import '../jahilimOsorio.css';
import Description  from '../components/Description/Description';

const JahilimOsorio_Kuty: React.FC = () => {
  const links = [
    { name: 'About me', path: '/people/jahilim-osorio/about-JOD' },
    { name: 'Projects', path: '/people/jahilim-osorio/projects-JOD'  },
    { name: 'Contact', path: 'mailto:jahilim29@gmail.com '  },
  ];
  const aboutJOD = useRef<HTMLDivElement>(null);
  const projectsJOD = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const scrollAndResetUrl = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        navigate('/people/jahilim-osorio/', { replace: true });
      }
    };

    if (id === 'about-JOD') {
      scrollAndResetUrl(aboutJOD);
    } else if (id === 'projects-JOD') {
      scrollAndResetUrl(projectsJOD);
    }
  }, [id, navigate]);

  return (
      <div>    
       <Helmet>
        <title> Jahilim Osorio - Portfolio</title>
        <meta name="title" content="Servicios de diseño Colombia Jahilim Osorio Diseñadora Portfolio" />
        <meta name="description" content='Graphic designer Colombia Jahilim Osorio Portfolio' />
        <meta name="description" content='Graphic designer Jahilim Osorio Colombia Portfolio' />
        <meta name="keywords" content="Jahilim Osorio, Jahilim, Diseñador Grafico, Jahilim Osorio Diseñadora Grafica, Portfolio Diseñador Grafico, Diseñadora Grafica Colombia" />
      </Helmet>
      <div className="background_jod">
        <BasicNavbar logoSrc={logo} links={links} />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Kuty+mocks/Banner.jpg" alt="Banner-kuty"  className='fullSize' />
        <Grid item xs={12} display="flex" justifyContent="end" mr={2}>
            <Typography fontFamily="raleway" fontSize={27} style={{fontStyle:"italic"}}>Team: Mariana Vanegas - Laura Montaña  - Jahilim Osorio </Typography>
          </Grid>
          <Description   
            description="Just-a Burger is an artisanal burger restaurant"
            category="branding  rebranding"
            color='#FF5A00'
            paragraph="a bakery and pastry shop founded in 1985 in the city of Cali. It is comprised of 10 partners from the same family, utilizing both artisanal and industrial techniques. The bread is primarily handmade, offering a homemade flavor. The brand identity does not incorporate any metaphor or concept in its logo; in fact, the name does not evoke any characteristic aspect of the brand. There are typographical inconsistencies across their packaging, vehicle fleet, social media, website, and editorial materials, which hinders the existence of a cohesive graphic identity."/>     
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Kuty+mocks/diagramacion.png" alt="kutyDiagramacion"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Kuty+mocks/3.jpg" alt="kuty3"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Kuty+mocks/2.jpg" alt="kuty2"  className='fullSize' />
        <img src="https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/Kuty+mocks/9.jpg" alt="kuty9"  className='fullSize' />

       <Footer/>
      </div>
    </div>
  );
}

export default JahilimOsorio_Kuty;
