import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className='kiria__footer section__padding'>
      <div className='kiria__footer-heading'>
        <h1 className='gradient__text'>
        ¿Quieres avanzar hacia el futuro antes que los demás?
        </h1>
      </div>
      <div className='kiria__footer-btn'>
        <p>Contactactanos</p>
      </div>
      <div className='kiria__footer-links'>
        <div className='kiria__footer-links_logo'>
          <h1  className='kiria__navbar-links_logo'>KIRIA GROUP</h1>
          <p>Pereira-Colombia, All Rights Reserved</p>
        </div>
    
        <div className='kiria__footer-links-div'>
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>

      </div>
      <div className='kiria__footer-copyright'>
        <p>
        © 2021 KIRIA GROUP. All rights reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer